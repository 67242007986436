import { Model } from "@vuex-orm/core";
import Customer from "./Customer";
import Vendor from "./Vendor";

import useDresskareGrpcClient from "@/composables/useDresskareGrpcClient";
import { create } from "@bufbuild/protobuf";
import {
  InvoiceController,
  InvoiceUpdateInvoiceStatusRequestSchema,
} from "@dresskare/dresskare-grpc-api/connect/services/dresskare_back/invoice_pb";
const invoiceClient = useDresskareGrpcClient(InvoiceController);

export default class Invoice extends Model {
  static entity = "invoice";

  static primaryKey = "uuid";

  static fields() {
    return {
      uuid: this.attr(null),
      createdAt: this.attr(null),
      updatedAt: this.attr(null),
      totalPrice: this.number(0),
      dateStart: this.attr(null),
      dateEnd: this.attr(null),
      vendor: this.attr(null),
      customer: this.attr(null),
      vendorObj: this.belongsTo(Vendor, "vendor", "uuid"),
      customerObj: this.belongsTo(Customer, "customer", "uuid"),
      category: this.string(),
      fullInvoiceNumber: this.string(),
      pdfFile: this.string(),
      invoiceStatus: this.string(),
      invoicePayedDatetime: this.attr(null),
      stripeId: this.string(),
      stripeStatus: this.string(),
      stripePdfUrl: this.string(),
      stripeHostedUrl: this.string(),
      stripeNumber: this.string(),
    };
  }

  static updateInvoiceStatusInDb = async (form) => {
    const response = await invoiceClient.updateInvoiceStatus(
      create(InvoiceUpdateInvoiceStatusRequestSchema, form),
      {}
    );

    if (!response) {
      return null;
    }
    this.insertOrUpdate({ data: response });
    return response;
  };

  static getInvoices = async (filters) => {
    try {
      const response = await invoiceClient.list({ Filters: filters });

      if (!response.results) {
        return null;
      }
      this.insertOrUpdate({ data: response.results });
      return response;
    } catch (error) {
      console.log("error:", error);
      return null;
    }
  };
}

export const STRIPE_INVOICE_CATEGORIES = {
  DRESSKARE_INVOICE: "DRESSKARE_INVOICE",
  DRESSKARE_FORMATION: "DRESSKARE_FORMATION",
  DRESSKARE_INVOICE_SHIPPING_LABEL: "DRESSKARE_INVOICE_SHIPPING_LABEL",
};

export const INVOICE_CATEGORIES = {
  MONTHLY_VENDOR: "MONTHLY_VENDOR",
  DEPOSIT_CUSTOMER: "DEPOSIT_CUSTOMER",
  DRESSKARE_COMMISSION: "DRESSKARE_COMMISSION",
  CLOTHES_BUY: "CLOTHES_BUY",
  CLOTHES_SELL: "CLOTHES_SELL",
  ...STRIPE_INVOICE_CATEGORIES,
};
export const INVOICE_STATUS = {
  NOT_PAYED: "NOT_PAYED",
  PAYED: "PAYED",
  PAYEMENT_CONFIRMED: "PAYEMENT_CONFIRMED",
};
