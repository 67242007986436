<template>
  <div>
    <DresskareCombobox
      autocomplete="off"
      :items="items"
      :value="internalValue.address ? internalValue : null"
      item-value="label"
      item-text="label"
      return-object
      :class="veryDense"
      :no-filter="true"
      v-bind="$attrs"
      :rules="[(v) => !!v || $t('fieldRequired')]"
      @input="setInternalValue"
      @update:search-input="debounceSearchElements($event)"
    >
      <template #item="{ item }">
        {{ item.label }}
      </template>
    </DresskareCombobox>
  </div>
</template>

<script>
import useGouvAddressApi from "@/composables/useGouvAddressApi";
import debounce from "lodash.debounce";
import { mapActions } from "vuex";

export default {
  name: "DresskareBaseGouvAddressCombobox",
  props: {
    // element can be regions, departements or communes
    value: {
      type: [String, Number],
      default: "",
    },
    extraParams: {
      type: Object,
      default: () => ({}),
    },
    minimumSearch: {
      type: Number,
      default: 1,
    },
  },
  setup(props, ctx) {
    return { ...useGouvAddressApi(ctx, props.minimumSearch) };
  },
  data() {
    return {
      items: [],
      internalValue: { address: "", postalCode: "", city: "", label: "" },
      defaultExtraParams: { limit: 20, type: "housenumber" },
    };
  },
  computed: {
    veryDense() {
      return { "very-dense": this.$vuetify.breakpoint.xsOnly };
    },
    isDisabled() {
      return (
        typeof this.$attrs.disabled !== "undefined" &&
        (this.$attrs.disabled || this.$attrs.disabled === "")
      );
    },
  },
  watch: {
    internalValue(newVal) {
      this.$emit("input", newVal);
    },
    value() {
      if (
        !this.internalValue ||
        this.value === this.internalValue.address ||
        this.value === this.internalValue.label
      ) {
        return;
      }
      this.initializeData();
    },
  },
  async created() {
    this.initializeData();
  },
  methods: {
    ...mapActions("notifications", ["showWarningNotification"]),
    setInternalValue(event) {
      if (!event) {
        this.internalValue = {
          address: "",
          postalCode: "",
          city: "",
          label: "",
        };
        return;
      }
      if (typeof event === "string") {
        this.internalValue = {
          address: event,
          postalCode: "",
          city: "",
          label: event,
        };
        return;
      }
      this.internalValue = event;
    },
    initializeData() {
      if (this.value) {
        this.internalValue.address = this.value;
        this.internalValue.label = this.value;
      }
    },
    debounceSearchElements: debounce(async function (query) {
      if (this.isDisabled) return;
      const responseDatas = await this.searchAddress(query);
      this.items = this.transformResponse(responseDatas);
    }, 500),
    transformResponse(responseDatas) {
      if (!responseDatas) {
        return [];
      }
      return responseDatas.features.map((adressFeature) => {
        const properties = adressFeature.properties;
        return {
          name: properties.name,
          address: properties.name,
          postalCode: properties.postcode,
          city: properties.city,
          label: properties.label,
          // JMF - 14/01/2024 - Geojson coordinates extraction [longitude, latitude]
          longitude: adressFeature.geometry.coordinates[0],
          latitude: adressFeature.geometry.coordinates[1],
        };
      });
    },
  },
};
</script>


<i18n>
{
  "en": {
    "fieldRequired": "This field is required"
  },
  "fr": {
    "fieldRequired": "Ce champ est obligatoire"
  }
}
</i18n>
