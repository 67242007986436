import useDresskareGrpcClient from "@/composables/useDresskareGrpcClient";
import { ShippingLabelController } from "@dresskare/dresskare-grpc-api/connect/services/dresskare_back/delivery_pb";
import { Model } from "@vuex-orm/core";
import PaymentProcess from "./PaymentProcess";
const shippingLabelClient = useDresskareGrpcClient(ShippingLabelController);

export default class ShippingLabel extends Model {
  static entity = "shippinglabel";

  static primaryKey = "uuid";

  static fields() {
    return {
      uuid: this.attr(null),
      createdAt: this.string(""),
      recipientContentType: this.string(),
      recipientObjectUuid: this.string(),
      weight: this.number(), // in kg
      isReturn: this.boolean(false),
      parcelNumber: this.string(""),
      reservationNumber: this.string(""),
      trackingNumber: this.string(""),
      shippedDresskareObjectUuid: this.string(""),
      deliveryStatus: this.number(),
      payment: this.string(""),
      paymentModel: this.belongsTo(PaymentProcess, "payment", "uuid"),
      cancelDateTime: this.string(""),
    };
  }

  static getShippingLabels = async (filters) => {
    try {
      const response = await shippingLabelClient.list({ Filters: filters }, {});

      if (!response.results) {
        return null;
      }
      this.insertOrUpdate({ data: response.results });
      return response;
    } catch (error) {
      console.error("error:", error);
      return null;
    }
  };

  static getShippingLabelByUuid = async (shippingLabelUuid) => {
    const shippingLabelFromStore = this.find(shippingLabelUuid);

    if (shippingLabelFromStore) {
      return shippingLabelFromStore;
    } else {
      try {
        const response = await shippingLabelClient.retrieve(
          { uuid: shippingLabelUuid },
          {}
        );

        if (!response) {
          return null;
        }
        this.insertOrUpdate({ data: response });
        return response;
      } catch (error) {
        console.error("error:", error);
        return null;
      }
    }
  };
}
