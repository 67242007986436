import useDresskareGrpcClient from "@/composables/useDresskareGrpcClient";
import i18n from "@/setup/i18n";
import store from "@/store";
import { create } from "@bufbuild/protobuf";
import { Model } from "@vuex-orm/core";

import {
  DepositProductCategoryController,
  DepositProductCategoryRequestSchema,
} from "@dresskare/dresskare-grpc-api/connect/services/dresskare_back/product_pb";

const depositProductCategoryClient = useDresskareGrpcClient(
  DepositProductCategoryController
);

export default class DepositProductCategory extends Model {
  static entity = "depositproductcategory";

  static primaryKey = "uuid";

  static fields() {
    return {
      uuid: this.attr(null),
      createdAt: this.attr(null),
      updatedAt: this.attr(null),
      deposit: this.attr(null),
      category: this.attr(null),
      productCount: this.number(0),
    };
  }

  static getDepositProductCategories = async (filters) => {
    try {
      let response = await depositProductCategoryClient.list({
        Filters: filters,
      });

      if (!response.results) {
        return null;
      }
      this.insertOrUpdate({ data: response.results });
      return response;
    } catch (error) {
      console.log("error:", error);
      return null;
    }
  };

  static create = async (form) => {
    try {
      const response = await depositProductCategoryClient.create(
        create(DepositProductCategoryRequestSchema, form),
        {}
      );

      this.insertOrUpdate({ data: response });
      return response;
    } catch (error) {
      console.log("error:", error);
      if (error.message.includes('"code": "unique"')) {
        store.dispatch(
          "notifications/showErrorNotification",
          i18n.t("errorDuplicate")
        );
      } else {
        store.dispatch("notifications/showErrorNotification", i18n.t("error"));
      }
      return null;
    }
  };

  static updateInDb = async (form) => {
    const response = await depositProductCategoryClient.update(
      create(DepositProductCategoryRequestSchema, form),
      {}
    );

    if (!response) {
      return null;
    }
    this.insertOrUpdate({ data: response });
    return response;
  };

  static destroy = async (depositProductCategoryUuid) => {
    try {
      const response = await depositProductCategoryClient.destroy(
        { uuid: depositProductCategoryUuid },
        {}
      );
      this.delete(depositProductCategoryUuid);
      return response;
    } catch (error) {
      console.log("error:", error);
      return null;
    }
  };

  static createFromDeposit = async (depositUuid) => {
    try {
      const response = await depositProductCategoryClient.createFromDeposit({
        depositUuid,
      });

      if (!response.results) {
        return null;
      }
      this.insertOrUpdate({ data: response.results });
      return response;
    } catch (error) {
      console.log("error:", error);
      return null;
    }
  };
}
