import useDresskareGrpcClient from "@/composables/useDresskareGrpcClient";
import { PaymentProcessController } from "@dresskare/dresskare-grpc-api/connect/services/dresskare_back/payment_pb";
import { Model } from "@vuex-orm/core";
import ShippingLabel from "./ShippingLabel";
const paymentProcessClient = useDresskareGrpcClient(PaymentProcessController);

export default class PaymentProcess extends Model {
  static entity = "paymentProcess";

  static primaryKey = "uuid";

  static fields() {
    return {
      uuid: this.attr(null),
      ownerObjectUuid: this.string(),
      ownerContentType: this.string(),
      paymentStatus: this.number(),
      lastSessionCreatedDatetime: this.string(),
      shippingLabels: this.hasMany(ShippingLabel, "shippingLabels", "uuids"),
      waitForConfirmationExpired: this.boolean(false),
    };
  }

  static getPaymentProcesses = async (filters) => {
    try {
      const response = await paymentProcessClient.list(
        { Filters: filters },
        {}
      );

      if (!response.results) {
        return null;
      }
      this.insertOrUpdate({ data: response.results });
      return response;
    } catch (error) {
      console.error("error:", error);
      return null;
    }
  };

  static getPaymentProcessByUuid = async (
    paymentProcessUuid,
    { forceLoad = true } = {}
  ) => {
    const paymentProcessFromStore = this.find(paymentProcessUuid);

    if (paymentProcessFromStore && !forceLoad) {
      return paymentProcessFromStore;
    } else {
      try {
        const response = await paymentProcessClient.retrieve(
          { uuid: paymentProcessUuid },
          {}
        );

        if (!response) {
          return null;
        }
        this.insertOrUpdate({ data: response });
        return response;
      } catch (error) {
        console.error("error:", error);
        return null;
      }
    }
  };
}
