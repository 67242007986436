import { Model } from "@vuex-orm/core";

import useDresskareGrpcClient from "@/composables/useDresskareGrpcClient";

import { create } from "@bufbuild/protobuf";
import {
  DeliveryPlaceController,
  DeliveryPlaceRequestSchema,
} from "@dresskare/dresskare-grpc-api/connect/services/dresskare_back/delivery_pb";

const deliverPlaceClient = useDresskareGrpcClient(DeliveryPlaceController);

export default class DeliveryPlace extends Model {
  static entity = "deliveryPlace";

  static primaryKey = "uuid";

  static fields() {
    return {
      uuid: this.attr(null),
      createdAt: this.attr(null),
      updatedAt: this.attr(null),
      deliveryService: this.number(0),
      deliveryServiceId: this.string(""),
      deliveryPlaceName: this.string(""),
      isActive: this.boolean(false),
    };
  }

  static getDeliveryPlaces = async (filters) => {
    try {
      let metadata = {};
      if (filters) {
        metadata.filters = JSON.stringify(filters);
      }

      const response = await deliverPlaceClient.list(undefined, {
        headers: metadata,
      });
      if (!response.results) {
        return null;
      }

      let deliveryPlaces = response.results;
      this.insertOrUpdate({ data: deliveryPlaces });
      return response;
    } catch (error) {
      console.error("error:", error);
      return null;
    }
  };

  static create = async (form) => {
    try {
      const response = await deliverPlaceClient.create(
        create(DeliveryPlaceRequestSchema, form),
        {}
      );
      this.insertOrUpdate({ data: response });
      return response;
    } catch (err) {
      console.error(err);
      return null;
    }
  };
  static updateInDb = async (form) => {
    try {
      const response = await deliverPlaceClient.update(
        create(DeliveryPlaceRequestSchema, form),
        {}
      );

      this.insertOrUpdate({ data: response });

      return response;
    } catch (err) {
      console.error(err);
      return null;
    }
  };
}
