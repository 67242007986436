import i18n from "@/setup/i18n";
import store from "@/store";
import axios from "axios";

const apiGouvUrl = process.env.VUE_APP_ADRESSE_API_GOUV_URL;

export default function (ctx, minimumSearch = 1) {
  const searchAddress = async (query) => {
    // check first char is letter or number
    const regex = /^[a-zA-Z0-9]/;
    const minSearchLength = Math.max(minimumSearch, 3);
    if (
      !query ||
      query.trim().length <= minSearchLength ||
      !regex.test(query[0])
    ) {
      return;
    }

    ctx.emit("search", query ?? "");

    const params = {
      q: query,
      limit: 20,
      type: "housenumber",
    };
    const urlParams = new URLSearchParams(params).toString();
    try {
      const response = await axios.get(`${apiGouvUrl}?${urlParams}`);
      return response.data;
    } catch (error) {
      store.dispatch(
        "notifications/showWarningNotification",
        i18n.t("errorFetchingGouvData")
      );
      console.error(error);
      return null;
    }
  };
  return {
    searchAddress,
  };
}
