<template>
  <VRow class="ma-0 pa-0">
    <VCol
      v-if="label && !useMaterials"
      cols="12"
      align="start"
      class="ma-0 pa-0"
    >
      <VRow no-gutters class="d-flex align-center">
        <VCol align="center" class="d-flex flex-grow-0">
          <p
            class="font-weight-medium mb-0 text--primary--theme"
            style="white-space: nowrap"
          >
            {{ label }}
          </p>
          <VTooltip v-if="tooltipText" top right max-width="400px">
            <template #activator="{ on }">
              <VIcon color="primary" class="ml-2" v-on="on"
                >mdi-information-outline</VIcon
              >
            </template>
            <span>{{ tooltipText }}</span>
          </VTooltip>
        </VCol>
      </VRow>
    </VCol>
    <VCol cols="12" class="ma-0 pa-0" :class="{ 'pt-1': !useMaterials }">
      <VCard v-bind="$attrs">
        <VProgressCircular v-if="loading" indeterminate color="primary" />
        <VList v-else-if="allowSelect">
          <VListItemGroup
            :value="itemSelectedIndex"
            color="primary"
            :mandatory="mandatory"
            @change="emitUpdateExceptIfDefault"
          >
            <VListItem v-for="(item, index) in items" :key="index" dense>
              <VListItemContent>
                <VListItemTitle>
                  <slot name="title" :item="item" :index="index"></slot>
                </VListItemTitle>
                <VListItemSubtitle>
                  <slot name="subtitle" :item="item" :index="index"></slot>
                </VListItemSubtitle>
              </VListItemContent>
              <VListItemAction>
                <slot name="action1" :item="item" :index="index"> </slot>
              </VListItemAction>
              <VListItemAction>
                <slot name="action2" :item="item" :index="index"> </slot>
              </VListItemAction>
              <VListItemAction>
                <slot name="action3" :item="item" :index="index"> </slot>
              </VListItemAction>
            </VListItem>
            <VListItem v-if="items.length === 0">
              <VListItemContent class="d-flex justify-center">
                {{ $t(emptyLabel || "emptyLabel") }}
              </VListItemContent>
            </VListItem>
            <VListItem v-if="addButon" dense>
              <VListItemContent dense class="d-flex justify-end">
                <VBtn icon @click="$emit('add-item')">
                  <VIcon>mdi-plus</VIcon>
                </VBtn>
              </VListItemContent>
            </VListItem>
          </VListItemGroup>
        </VList>
        <VList v-else>
          <VListItem v-for="(item, index) in items" :key="index" dense>
            <VListItemContent>
              <VListItemTitle>
                <slot name="title" :item="item" :index="index"></slot>
              </VListItemTitle>
              <VListItemSubtitle>
                <slot name="subtitle" :item="item" :index="index"></slot>
              </VListItemSubtitle>
            </VListItemContent>
            <VListItemAction>
              <slot name="action1" :item="item" :index="index"> </slot>
            </VListItemAction>
            <VListItemAction>
              <slot name="action2" :item="item" :index="index"> </slot>
            </VListItemAction>
            <VListItemAction>
              <slot name="action3" :item="item" :index="index"> </slot>
            </VListItemAction>
          </VListItem>
          <VListItem v-if="items.length === 0">
            <VListItemContent class="d-flex justify-center">
              {{ $t(emptyLabel || "emptyLabel") }}
            </VListItemContent>
          </VListItem>
          <VListItem v-if="addButon" dense>
            <VListItemContent dense class="d-flex justify-end">
              <VBtn icon @click="$emit('add-item')">
                <VIcon>mdi-plus</VIcon>
              </VBtn>
            </VListItemContent>
          </VListItem>
        </VList>
      </VCard>
    </VCol>
  </VRow>
</template>

<script>
export default {
  name: "DresskareBaseItemList",
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    label: {
      type: String,
      default: "",
    },
    useMaterials: {
      type: Boolean,
      default: false,
    },
    addButon: {
      type: Boolean,
      default: false,
    },
    emptyLabel: {
      type: String,
      default: "",
    },
    loading: {
      type: Boolean,
      default: false,
    },
    itemSelectedIndex: {
      type: [String, Number],
      default: null,
    },
    allowSelect: {
      type: Boolean,
      default: false,
    },
    mandatory: {
      type: Boolean,
      default: false,
    },
    tooltipText: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      internalValue: [],
      initialSelectedvalue: null,
    };
  },
  methods: {
    emitUpdateExceptIfDefault(event) {
      // If there is no selected item passed as parameter a first init select is emited.
      // We do not want it as we want the first the user is clicking on
      if (
        (this.itemSelectedIndex === null ||
          typeof this.itemSelectedIndex === "undefined" ||
          this.itemSelectedIndex === -1) &&
        this.initialSelectedvalue === null
      ) {
        this.initialSelectedvalue = event;
      } else {
        this.$emit("update:itemSelectedIndex", event);
      }
    },
  },
};
</script>


<i18n>
  {
    "en": {
      "emptyLabel": "No item"
    },
    "fr": {
      "emptyLabel": "Aucun élément"
    }
  }
  </i18n>
