import useSubscriptionHelper from "@/composables/useSubscriptionHelper";
import useUserPermissions from "@/composables/useUserPermissions";
import { create } from "@bufbuild/protobuf";
import { Model } from "@vuex-orm/core";
import Announcement from "./Announcement";
import Brand from "./Brand";
import Color from "./Color";
import ProductImage from "./ProductImage";
import ProductSize from "./ProductSize";

import useDresskareGrpcClient from "@/composables/useDresskareGrpcClient";

import {
  ProductController,
  ProductPartialUpdateRequestSchema,
  ProductRequestSchema,
  ProductWithDepositImagesRequestSchema,
} from "@dresskare/dresskare-grpc-api/connect/services/dresskare_back/product_pb";
const productClient = useDresskareGrpcClient(ProductController);

const { checkInErrorIfSubscriptionUpdateNeeded } = useSubscriptionHelper();

const fillAnnouncementInProduct = (product) => {
  const { currentUser } = useUserPermissions();
  if (currentUser?.value?.favoriteAnnouncement) {
    product.announcementUuid = currentUser.value.favoriteAnnouncement;
  }
  return product;
};

export default class Product extends Model {
  static entity = "product";

  static primaryKey = "uuid";

  static fields() {
    return {
      uuid: this.attr(null),
      createdAt: this.attr(null),
      updatedAt: this.attr(null),
      deposit: this.attr(null),
      reference: this.string(""),
      stockEntryDatetime: this.string(null).nullable(),
      name: this.string(""),
      category: this.string("").nullable(),
      brand: this.string("").nullable(),
      brandObj: this.belongsTo(Brand, "brand"),
      quality: this.string("").nullable(),
      suggestedPrice: this.number(0),
      selledPrice: this.number(0),
      sellDatetime: this.string("").nullable(),
      payedDatetime: this.string("").nullable(),
      saleStatus: this.string("").nullable(),
      productLinks: this.attr([]),
      comment: this.string("").nullable(),
      onlineDatetime: this.string(null).nullable(),
      colors: this.attr([]),
      colorsObj: this.hasManyBy(Color, "colors"),
      saleStatusModified: this.string(null).nullable(),
      inInvoices: this.boolean(false),
      importedSaleStatus: this.string().nullable(),
      vendorIncome: this.number(0),
      customerIncome: this.number(0),
      dresskareIncome: this.number(0),
      images: this.hasMany(ProductImage, "product"),
      isPriceAutomatic: this.boolean(true),
      taxEligible: this.string(""),
      subGender: this.string(""),
      productSize: this.string("").nullable(),
      productSizeObj: this.belongsTo(ProductSize, "productSize"),
      //INFO - AM - 01/05/2024 - announcement associated to product for extension
      announcementUuid: this.attr(null),
      announcement: this.belongsTo(Announcement, "announcementUuid"),
      notificationSellSentToCustomer: this.boolean(false),
      sellOn: this.string(""),
      defaultImageKind: this.number(0),
    };
  }

  static getProducts = async (filters, fillAnnouncement = false) => {
    try {
      let metadata = {};
      if (filters) {
        metadata.filters = JSON.stringify(filters);
      }

      const response = await productClient.list(undefined, {
        headers: metadata,
      });

      if (!response.results) {
        return null;
      }
      let products = response.results;
      if (fillAnnouncement) {
        products = products.map(fillAnnouncementInProduct);
      }
      this.insertOrUpdate({ data: products });
      return response;
    } catch (error) {
      console.error("error:", error);
      return null;
    }
  };

  static create = async (form, beforeInsertCallback = null) => {
    try {
      const response = await productClient.create(
        create(ProductRequestSchema, form),
        {}
      );

      if (beforeInsertCallback) {
        beforeInsertCallback(response);
      }

      this.insertOrUpdate({ data: response });
      return response;
    } catch (error) {
      const isSubscriptionUpdateNeeded =
        checkInErrorIfSubscriptionUpdateNeeded(error);
      if (!isSubscriptionUpdateNeeded) {
        console.error("error:", error);
      }
      return null;
    }
  };

  static updateInDb = async (form) => {
    const response = await productClient.update(
      create(ProductRequestSchema, form),
      {}
    );

    if (!response) {
      return null;
    }
    this.insertOrUpdate({ data: response });
    return response;
  };

  static partialUpdateInDb = async (partialForm, fields) => {
    const response = await productClient.partialUpdate(
      create(ProductPartialUpdateRequestSchema, {
        ...partialForm,
        PartialUpdateFields: fields,
      }),
      {}
    );

    if (!response) {
      return null;
    }
    this.insertOrUpdate({ data: response });
    return response;
  };

  static getProductByUuid = async (productUuid) => {
    const productFromStore = this.find(productUuid);

    if (productFromStore) {
      return productFromStore;
    } else {
      try {
        const response = await productClient.retrieve(
          { uuid: productUuid },
          {}
        );

        if (!response) {
          return null;
        }
        this.insertOrUpdate({ data: response });
        return response;
      } catch (error) {
        console.error("error:", error);
        return null;
      }
    }
  };

  static destroy = async (productUuid) => {
    try {
      await productClient.destroy({ uuid: productUuid }, {});
      this.delete(productUuid);
    } catch (error) {
      console.error("error:", error);
    }
  };

  static createFromDepositImages = async (
    form,
    depositUuid,
    depositImagesUuids
  ) => {
    try {
      form.deposit = depositUuid;
      form.depositImagesUuids = depositImagesUuids;
      const response = await productClient.createFromDepositImages(
        create(ProductWithDepositImagesRequestSchema, form),
        {}
      );

      this.insertOrUpdate({ data: response });
      return response;
    } catch (error) {
      const isSubscriptionUpdateNeeded =
        checkInErrorIfSubscriptionUpdateNeeded(error);
      if (!isSubscriptionUpdateNeeded) {
        console.error("error:", error);
      }
      return null;
    }
  };
}

export const PRODUCT_QUALITY = {
  NEW_WITH_TICKET: "NEW_WITH_TICKET",
  NEW_WITHOUT_TICKET: "NEW_WITHOUT_TICKET",
  VERY_GOOD: "VERY_GOOD",
  GOOD: "GOOD",
  SATISFYING: "SATISFYING",
  REFUSED: "REFUSED",
};

export const SALE_STATUS = {
  FACTURED: "FACTURED",
  PAYED: "PAYED",
  SELL: "SELL",
  ONLINE: "ONLINE",
  OFFLINE: "OFFLINE",
  RESERVED: "RESERVED",
  REFUSED: "REFUSED",
  RESTITUED: "RESTITUED",
};

export const SUB_GENDER = {
  BOY: "BOY",
  GIRL: "GIRL",
  MIXTE: "MIXTE",
};

export const SELL_PLATFORM = {
  VINTED: "VINTED",
  DEPOP: "DEPOP",
  VESTIAIRE_COLLECTIVE: "VESTIAIRE_COLLECTIVE",
  EBAY: "EBAY",
  FACEBOOK_MARKETPLACE: "FACEBOOK_MARKETPLACE",
  INSTAGRAM: "INSTAGRAM",
  OTHER_SOCIAL_NETWORK: "OTHER_SOCIAL_NETWORK",
  AMAZON: "AMAZON",
  LEBONCOIN: "LEBONCOIN",
  WEBSITE: "WEBSITE",
  FLEA_MARKET: "FLEA_MARKET",
  OTHER: "OTHER",
};
